import { Loading, LoadingScreen } from '@segunosoftware/equinox';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { useIsFetching } from '@tanstack/react-query';
import { Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import I18nProvider from './components/I18nProvider';
import OAuth from './components/OAuth';
import ProtectedRoute from './components/ProtectedRoute';
import SentryWrapper from './components/SentryWrapper';
import SupportChat from './components/SupportChat';
import { useLocaleParam } from './hooks/useLocaleParam';
import { CanvaCallback, CanvaDesignUpload, CanvaReturnNavigation, Dashboard, Welcome } from './utils/lazy-load';

export default function App() {
	const locale = useLocaleParam();
	const location = useLocation();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<SentryWrapper>
				<Suspense fallback={<LoadingScreen />}>
					<Routes>
						<Route path="/canva/return" element={<CanvaReturnNavigation />} />
						<Route
							path="/*"
							element={
								<Routes>
									<Route path="/oauth/canva/callback" element={<ProtectedRoute element={<CanvaCallbackEmbed locale={locale} />} />} />
									<Route path="/oauth/*" element={<OAuth />} />
									<Route path="/*" element={<ProtectedRoute element={<AppEmbed locale={locale} />} />} />
								</Routes>
							}
						/>
					</Routes>
				</Suspense>
			</SentryWrapper>
		</AppProvider>
	);
}

type CanvaCallbackEmbedProps = {
	locale?: string;
};

function CanvaCallbackEmbed({ locale }: CanvaCallbackEmbedProps) {
	return (
		<I18nProvider localeParam={locale}>
			<CanvaCallback />
		</I18nProvider>
	);
}

type AppEmbedProps = {
	locale?: string;
};

function AppEmbed({ locale }: AppEmbedProps) {
	const isFetching = useIsFetching();
	const calculatedLoading = isFetching > 0;
	return (
		<I18nProvider localeParam={locale}>
			<Suspense fallback={<LoadingScreen delay={500} />}>
				<Routes>
					<Route path="design/upload" element={<CanvaDesignUpload />} />
					<Route path="welcome" element={<Welcome />} />
					<Route path="" element={<Dashboard />} />
				</Routes>
			</Suspense>
			<SupportChat />
			{calculatedLoading && <Loading />}
		</I18nProvider>
	);
}
