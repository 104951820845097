import { LoadingScreen, Stack, useNavigate, useQueryString } from '@segunosoftware/equinox';
import { Button, Modal, Text } from '@shopify/polaris';
import { useMutation } from '@tanstack/react-query';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import type { Get } from '../hooks/types';
import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch';

const PERMISSIONS_TEXT =
	'This app requires permissions to access some of the data on your Shopify account. Click continue to review and approve the necessary permissions.';

type RedirectUrlResponse = {
	redirectUrl: string;
};

export default function OAuth() {
	return (
		<Routes>
			<Route path="shopify/install" element={<ShopifyInstallComponent />} />
			<Route path="shopify/callback" element={<ShopifyCallbackComponent />} />
		</Routes>
	);
}

type RedirectComponentProps = {
	path: string;
	text: string;
};

function RedirectComponent({ path, text }: RedirectComponentProps) {
	const navigate = useNavigate();
	const params = useQueryString();
	const { get } = useAuthenticatedFetch() as Get<RedirectUrlResponse>;
	const [performMutation, setPerformMutation] = useState(false);
	const [modalOpen, setModalOpen] = useState(true);
	const { mutate, data } = useMutation({
		mutationFn: () => get(`${path}?${queryString.stringify(params)}`)
	});
	const redirectUrl = data?.redirectUrl;

	useEffect(() => {
		setPerformMutation(true);
	}, []);

	useEffect(() => {
		if (performMutation) {
			mutate();
		}
	}, [performMutation, mutate]);

	if (redirectUrl) {
		if (redirectUrl.indexOf('/') !== 0) {
			return (
				<>
					{!modalOpen && (
						<LoadingContainer>
							<MaxWidth>
								<Stack alignment="center" vertical>
									<Text as="p">{text}</Text>
									<Button onClick={() => navigate({ to: redirectUrl })}>Continue</Button>
								</Stack>
							</MaxWidth>
						</LoadingContainer>
					)}
					<Modal
						title="Continue to Shopify"
						open={modalOpen}
						onClose={() => setModalOpen(false)}
						primaryAction={{
							content: 'Continue',
							onAction: () => navigate({ to: redirectUrl })
						}}
						secondaryActions={[
							{
								content: 'Cancel',
								onAction: () => setModalOpen(false)
							}
						]}>
						<Modal.Section>
							<p>{text}</p>
						</Modal.Section>
					</Modal>
				</>
			);
		} else {
			return <Navigate to={redirectUrl} />;
		}
	}

	return <LoadingScreen>Connecting to Shopify...</LoadingScreen>;
}

function ShopifyInstallComponent() {
	const params = useQueryString();
	if (!params.host && !params.shop) {
		// This workaround is for a Shopify App Bridge bug where they don't pass us the auth query parameters
		return <Navigate to="/" />;
	}
	return <RedirectComponent path="/oauth/shopify/install" text={PERMISSIONS_TEXT} />;
}

function ShopifyCallbackComponent() {
	return <RedirectComponent path="/oauth/shopify/callback" text={PERMISSIONS_TEXT} />;
}

const LoadingContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const MaxWidth = styled.div`
	max-width: 525px;
	text-align: center;
`;
